import cookie from 'js-cookie';
import { GetServerSidePropsContext } from 'next';

const isBrowser = typeof global.window !== 'undefined';
const namespace = 'paw';

type PawlyticsCookies = 'jwt' | 'idToken' | 'organization';

export function setCookie(
  key: PawlyticsCookies,
  value: string,
  expires?: number
) {
  if (isBrowser) {
    cookie.set(`${namespace}.${key}`, value, {
      expires: expires || 1,
      path: '/',
      sameSite: 'strict',
    });
  }
}

export function removeCookie(key: PawlyticsCookies) {
  if (isBrowser) {
    cookie.remove(`${namespace}.${key}`, { expires: 1 });
  }
}

function getCookieFromServer(
  key: string,
  req: GetServerSidePropsContext['req']
): string | undefined {
  if (!req.headers.cookie) {
    return undefined;
  }

  const rawCookie = req.headers.cookie
    .split(';')
    .find((c) => c.trim().startsWith(`${key}=`));

  if (!rawCookie) {
    return undefined;
  }

  return rawCookie.split('=')[1];
}

export function getCookie(
  key: PawlyticsCookies,
  context?: GetServerSidePropsContext['req']
): string | undefined {
  return context
    ? getCookieFromServer(`${namespace}.${key}`, context)
    : cookie.get(`${namespace}.${key}`);
}
