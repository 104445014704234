export const primary = '#652D90';
export const secondary = '#00BECB';
export const danger = '#ff5b5b';
export const gray50 = '#b2b2b2';
export const grayLight = '#e6e6e6';
export const grayDark = '#898989';

export const blueLight = '#60c4ec';
export const blue = '#1D73B9';
export const pink = '#ff90a3';

export const warning = '#FFA100';
export const positive = '#18a9b3';
export const coralDark = '#f37a8e';

// teal, coral, orange, and purple are used as accent colors throughout the
// product
export const teal = '#009CA7';
export const coral = '#FE8497';
export const orange = '#E46C22';
export const purple = primary;
// alts are used for placing these colors on top of each other in an fg/bg
// pattern
export const tealAlt = '#dbf4f5';
export const coralAlt = '#ffeff3';
export const orangeAlt = '#fff8f2';
export const purpleAlt = '#eee4f3';

// rainbow badge colors
export const lemon = '#EBFE7E';
export const lime = '#BDFB8F';
export const mint = '#92F3A5';
export const seafoam = '#6FE9BB';
export const aqua = '#5CDDCE';
export const turquoise = '#63CED9';
export const azure = '#7ABDDB';
export const periwinkle = '#95AAD4';
export const lavender = '#AC97C3';
export const mauve = '#BA85AB';
export const plum = '#C07590';
export const cherry = '#BC6974';

export const lemonAlt = '#fbffe5';
export const limeAlt = '#f2fee9';
export const mintAlt = '#e9fded';
export const seafoamAlt = '#e2fbf1';
export const aquaAlt = '#def8f5';
export const turquoiseAlt = '#e0f5f7';
export const azureAlt = '#e4f2f8';
export const periwinkleAlt = '#eaeef6';
export const lavenderAlt = '#eeeaf3';
export const mauveAlt = '#f1e7ee';
export const plumAlt = '#f2e3e9';
export const cherryAlt = '#f2e1e3';

export const labelGrey = '#616269';
