import gql from 'graphql-tag';
import { OrganizationUser } from 'types/organization';
import { PersonFragment, OrgFragment } from './fragments';

export const queryCurrentUser = gql`
  {
    user_current {
      id
      profile {
        ...PersonFragment
      }
      settings {
        text_notifications
        email_notifications
        in_app_notifications
        date_format
        time_format
        timezone
      }
      date_registered
    }
    user_permissions {
      role
      organization {
        ...OrgFragment
      }
    }
  }
  ${OrgFragment}
  ${PersonFragment}
`;

export interface OrganizationUsersQueryData {
  organization_users: Array<OrganizationUser>;
}
export interface OrganizationUsersQueryVariables {
  id: string;
  filter?: { user_role: string | null };
  paginations?: {
    limit: number;
    sort_order: null | string;
    sort_key: null | string;
  };
}

export const GetNotifications = gql`
  query userNotifications(
    $id: UUID!
    $pagination: Input_Notifications_Pagination
    $next: String
  ) {
    user_notifications2(pagination: $pagination, next_token: $next) {
      total_count
      limit
      offset
      next_token
      entities {
        date
        id
        title
        message
        is_read
        is_sent
        ... on Notification_MedicalEvent {
          pet_medical_event {
            id
            pet {
              id
            }
          }
        }
        recipient {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`;

export const GetNotificationCount = gql`
  query GetNotificationCount($id: UUID!) {
    user_notifications2 {
      entities {
        is_read
      }
    }
  }
`;
