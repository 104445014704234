import Lottie from 'lottie-react';
import { animationData } from 'lib/constants/animation';
import styled from 'styled-components';
import { ComponentProps } from 'react';

type Props = {
  $size?: 's' | 'm';
  // <Lottie /> requires animationData to be provided, which we do in attrs, but
  // styled-components doesn’t recognize that and omit it from downstream
  // consumers
  animationData?: never;
  // we want to prevent custom styles and require using `$size`
  style?: never;
};

type LottieProps = ComponentProps<typeof Lottie>;
const defaultAttrs: LottieProps = {
  animationData: animationData as LottieProps['animationData'],
  autoplay: true,
  loop: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
// FIXME: why is defaultAttrs having to be `any`d to scrub ~30 type errors
export const Loader = styled(Lottie).attrs<Props>(defaultAttrs as any)`
  height: 400px;
  width: 400px;
  margin: 0 auto;
  ${({ $size }) =>
    $size === 's'
      ? `height: 48px; width: 48px;`
      : $size === 'm'
        ? `height: 96px; width: 96px`
        : ''}
`;
