/**
 * This module provides the foundational abstractions for working with localized
 * date throughout the app. This can be roughly summarized as:
 *
 * * Formatters: format standard data structures into localized settings
 * * Translaters: translate/interpolate text for localized settings
 * * Timezone calculations: abstract computer, server, and user+org timezoness
 */

import { parseISO, startOfDay, sub, format } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export type DateFormat = 'MMDDYYYY' | 'YYYYMMDD' | 'DDMMYYYY';
export type TimeFormat = '12' | '24';
export type Timezone = string; // TODO:make this a union of accepted values

export const TIMEZONE = [
  { label: 'Pacific/Honolulu', value: 'Pacific/Honolulu' },
  { label: 'America/Anchorage', value: 'America/Anchorage' },
  { label: 'Pacific Time/Los Angeles', value: 'America/Los_Angeles' },
  { label: 'Mountain Time/Phoenix', value: 'America/Boise' },
  { label: 'Central Time/Chicago', value: 'America/Chicago' },
  { label: 'Eastern Time/New York', value: 'America/New_York' },
  { label: 'America/Caracas', value: 'America/Caracas' },
  { label: 'America/Puerto Rico', value: 'America/Puerto_Rico' },
  { label: 'America/St Johns', value: 'America/St_Johns' },
  { label: 'America/Buenos Aires', value: 'America/Buenos_Aires' },
  { label: 'Atlantic/Cape Verde', value: 'Atlantic/Cape_Verde' },
  { label: 'Atlantic/Reykjavik', value: 'Atlantic/Reykjavik' },
  { label: 'Europe/Dublin', value: 'Europe/Dublin' },
  { label: 'Africa/Tripoli', value: 'Africa/Tripoli' },
  { label: 'Europe/Moscow', value: 'Europe/Moscow' },
  { label: 'Asia/Dubai', value: 'Asia/Dubai' },
  { label: 'Asia/Tehran', value: 'Asia/Tehran' },
  { label: 'Asia/Kabul', value: 'Asia/Kabul' },
  { label: 'Asia/Karachi', value: 'Asia/Karachi' },
  { label: 'Asia/Kolkata', value: 'Asia/Kolkata' },
  { label: 'Asia/Kathmandu', value: 'Asia/Kathmandu' },
  { label: 'Asia/Almaty', value: 'Asia/Almaty' },
  { label: 'Asia/Rangoon', value: 'Asia/Rangoon' },
  { label: 'Asia/Jakarta', value: 'Asia/Jakarta' },
  { label: 'Asia/Hong Kong', value: 'Asia/Hong_Kong' },
  { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
  { label: 'Australia/Darwin', value: 'Australia/Darwin' },
  { label: 'Australia/Canberra', value: 'Australia/Canberra' },
  { label: 'Pacific/Guam', value: 'Pacific/Guam' },
  { label: 'Pacific/Guadalcanal', value: 'Pacific/Guadalcanal' },
  { label: 'Pacific/Auckland', value: 'Pacific/Auckland' },
  { label: 'Pacific/Apia', value: 'Pacific/Apia' },
] as const;

export const TIMEFORMAT = [
  { label: '12 hour ( AM / PM )', value: '12' },
  { label: '24 hour ( 16:30 )', value: '24' },
] as const;

export const DATEFORMAT = [
  { label: 'MM / DD / YYYY', value: 'MMDDYYYY' },
  { label: 'DD / MM / YYYY', value: 'DDMMYYYY' },
  { label: 'YYYY / MM / DD', value: 'YYYYMMDD' },
];

export type LocaleSettings = {
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  timezone: Timezone;
  currency: string;
  unitsOfMeasurement: 'IMPERIAL' | 'METRIC';
  countryCodePrefix: string;
  addressStyle: 'US' | 'UNIVERSAL';
};

/***
 * `formatPhoneNumber` formats incoming phone string containing
 * country code and phone number separated by a space and spits out
 * joined number with plus sign appended to front
 */
export function formatPhoneNumber(ph: string | null): string {
  if (ph == null) return '';
  const parts = ph.split(' ');
  // legacy numbers that have exactly one space in them will have "+" added to the front of number;
  if (parts.length !== 2) return ph;
  return '+' + parts.join('');
}

/**
 * `localizeDate` takes a UTC Date string from server data and LocaleSettings we
 * construct in `UserContext` to format a date according to user preferences.
 */
export function localizeDate(
  date: string,
  localeSettings: LocaleSettings
): string {
  switch (localeSettings.dateFormat) {
    case 'DDMMYYYY':
      return format(parseISO(date), 'dd/MM/yyyy');
    case 'YYYYMMDD':
      return format(parseISO(date), 'yyyy/MM/dd');
    default:
      return format(parseISO(date), 'MM/dd/yyyy');
  }
}

/**
 * `localizeTime` takes in an ISO Date string from server data and the
 * LocaleSettings we construct in `UserContext` to format the time from a Date
 * object according to user preferences.
 */
export function localizeTime(
  dateString: string,
  localeSettings: LocaleSettings
): string {
  if (localeSettings.timezone) {
    return format(
      utcToZonedTime(parseISO(dateString), localeSettings.timezone),
      getLocalizedTimeFormat(localeSettings)
    );
  } else {
    return format(parseISO(dateString), getLocalizedTimeFormat(localeSettings));
  }
}

/**
 * `getLocalizedTimeFormat` maps the proprietary LocaleSettings format and to
 * the standardized formatting string
 */
export function getLocalizedTimeFormat(localeSettings: LocaleSettings): string {
  if (localeSettings.timeFormat === '24') {
    return 'H:mm';
  } else {
    return 'h:mm aaaa';
  }
}

const translations = {
  line1({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Address (line 1)' : 'Street 1';
  },
  line2({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Address (line 2)' : 'Street 2';
  },
  locality({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'City / Town' : 'City';
  },
  postal_code({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Zip code / Postal code' : 'Zip code';
  },
  state({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'State / Province' : 'State';
  },
  country_code({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Country / Territory' : 'Country';
  },
};
/**
 * `localize` is our minimal translation interface. It is similar ot the `t`
 * function often found in localization libraries. While we are not yet
 * localized and have minimal internationalization functionality, we have set up
 * a similar function to reduce the cost on maintenance for us and have a
 * convenient path forward to full i18n someday without paying the cost for it
 * now.
 *
 * Usage:
 *   localize('line1', localeSettings); // returns string
 *   localize('non-existent-key', localeSettings); // throws TypeError
 */
export function localize(
  key: keyof typeof translations,
  localeSettings: LocaleSettings
): string {
  if (!translations.hasOwnProperty(key)) {
    throw new TypeError(`No translation key for "${key}"`);
  }

  if (typeof translations[key] === 'function') {
    return translations[key](localeSettings);
  } else {
    return translations[key] as unknown as string;
  }
}

export function isValidDate(date: Date) {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a date
    return !isNaN(date.getTime());
  }
  return false;
}

/***
 * This is used *only* for cron expression generation in medical reminders.
 *
 * TODO: move to `_cron.ts` after ongoing changes for modern medical land.
 */
export function getTimeInUtcFromLocale(
  time: string,
  localeSettings: LocaleSettings
) {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const monthString = month.toString().padStart(2, '0');
  const day = currentDate.getDate();
  const dayString = day.toString().padStart(2, '0');
  const isoTime = `${year}-${monthString}-${dayString} ${time}`;

  //must give zonedTimeToUtc a date string or it uses browser tz
  const utcDate = zonedTimeToUtc(isoTime, localeSettings.timezone);
  const hours = utcDate.getUTCHours().toString().padStart(2, '0');
  const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

/**
 * Returns a Date object at midnight in the user-selected time zone today.
 * Note that today in the time zone selected may be tomorrow or yesterday based
 * on computer time
 *
 * > Important! In our date interaction data flow, this should be the default
 * > date value for any user input when the `time` value is not used.
 */
export function getMidnightRightNowInUserSettingLocale(
  localeSettings: LocaleSettings
): Date {
  const browserOffsetInMinutes = new Date().getTimezoneOffset();

  const userLocaleSettingOffsetInMs = getTimezoneOffset(
    localeSettings.timezone
  );
  const userLocaleSettingsOffsetInMinutes =
    userLocaleSettingOffsetInMs / 1000 / 60;

  const rightNow = new Date().toJSON();

  const rightNowInUserSettingsTimeZone = utcToZonedTime(
    rightNow,
    localeSettings.timezone
  );

  const beginningOfDayInUserSettingsTimeZone = startOfDay(
    rightNowInUserSettingsTimeZone
  );

  return sub(beginningOfDayInUserSettingsTimeZone, {
    minutes: browserOffsetInMinutes + userLocaleSettingsOffsetInMinutes,
  });
}

/**
 * Returns a Date object at the current time in the user-selected time zone.
 * Note that today in the time zone selected may be tomorrow or yesterday based
 * on computer time
 *
 * > Important! In our date interaction data flow, this should be the default
 * > date value for any user input when the `time` value is used.
 */
export function getRightNowInUserSettingLocale(
  localeSettings: LocaleSettings
): Date {
  const rightNow = new Date().toJSON();

  return utcToZonedTime(rightNow, localeSettings.timezone);
}

/**
 * Returns a Date object at the current time in the user-selected time zone.
 * Note that today in the time zone selected may be tomorrow or yesterday based
 * on computer time
 *
 * > Important! In our date interaction data flow, this should be used for all
 * > edit flows that accept a datetime value from the server.
 */
export function getDateInUserSettingLocale(
  utcDate: string,
  localeSettings: LocaleSettings
): Date {
  return utcToZonedTime(utcDate, localeSettings.timezone);
}

/***
 * `setTimeToLocaleSettings`: this function is required to reconcile Date, user
 * settings, and ISO time formats to appropriately send dates to the server.
 *
 * We need to convert from user-selected timezone to browser timezone so that we can then go from User Selected Time Zone to UTC
 * eg if I select 2AM while I have the User Settings of ET, but have my Browser settings in CT, we need to subtract an hour from the date (1AM CT = 2AM ET)
 *
 * The user experience flow:
 * Date: [DD/MM/YYYY]  Time: [hh:mm:am]
 *                           [ Submit ]
 * --> `const serverDate = setTimeToLocaleSettings(form.date, form.time, localeSettings);
 * --> `await mutation({ variables: { input: { date: serverDate } } });
 */
export function setTimeToLocaleSettings(
  date: Date,
  timestring: string,
  { timezone }: LocaleSettings
): Date {
  const dateInUserSelectedTime = utcToZonedTime(date, timezone);
  const [hours, minutes] = timestring.split(':');
  dateInUserSelectedTime.setHours(parseInt(hours, 10));
  dateInUserSelectedTime.setMinutes(parseInt(minutes, 10));
  dateInUserSelectedTime.setSeconds(0);
  dateInUserSelectedTime.setMilliseconds(0);

  return __timeZoneToBrowserTime(dateInUserSelectedTime, timezone);
}

// exported for unit tests
export function __timeZoneToBrowserTime(date: Date, timeZone: string) {
  return zonedTimeToUtc(date, timeZone);
}

/***
 * `parseTimeFromDate` is used in the editing flow, that takes a Date object
 * that is already in the user timezone and formats it for <input type="time" />
 */
export function parseTimeFromDate(date: Date | null) {
  if (date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else {
    return '00:00';
  }
}

/**
 * timezone of computer. Use this as the default value if we do not have one
 * provided by the server for user options.
 */
export const computersTimezone =
  Intl.DateTimeFormat().resolvedOptions().timeZone;
