import * as Sentry from '@sentry/browser';

export function initializeSentry() {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    release: 'pawlytics@' + process.env.GIT_SHA,
    environment: 'production',
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
    denyUrls: [
      'http://localhost:3000/',
      'http://pawlytics.ngrok.io/',
      'https://pawlytics.ngrok.io/',
    ],
  });
}

export function setSentryUser(email: string) {
  Sentry.configureScope((scope) => {
    scope.setUser({ email });
  });
}
