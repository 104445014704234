/**
 * This interface is a wrapper around our authentication merchanisms:
 * * login
 * * signup
 * * reset-password
 *
 * Logging out can and should only be done by the `/logout` route to ensure all
 * cleanup is done
 */
import * as Sentry from '@sentry/browser';
import auth0 from 'auth0-js';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import { setCookie, getCookie, removeCookie } from 'lib/session';
import { setSentryUser } from 'helpers/sentry';

interface Auth0Result {
  accessToken: string;
  expiresIn: number;
  idToken: string;
}
const isBrowser = typeof global.window !== 'undefined';

export const channel =
  isBrowser && typeof global.BroadcastChannel !== 'undefined'
    ? new BroadcastChannel('pawlytics-auth')
    : null;
if (channel) {
  channel.addEventListener('message', (event) => {
    const { type, payload } = event.data;
    switch (type) {
      case 'login':
        // @ts-expect-error
        window.location = window.location.origin;
        return saveToken(payload);
      case 'logout':
        // @ts-expect-error
        window.location = window.location.origin + 'login';
        return;
      case 'signup':
        saveToken(payload);
        // @ts-expect-error
        window.location = window.location.origin;
        return;
      // this should never be hit but is a runtime error
      // istanbul ignore next
      default:
        throw new TypeError(`Unknown message channel type: ${type}`);
    }
  });
}

const webAuth = new auth0.WebAuth({
  domain: 'pawlytics.auth0.com',
  clientID: 'Uu26_hnPqSaCy6Fd-InrGwKmmyK7sHwd',
  scope: 'openid profile email',
  audience: 'https://api.pawlytics.com',
  responseType: 'token id_token',
  redirectUri: global.location?.origin,
});

/***
 * This is used by the `/login` route
 */
export async function login({
  username,
  password,
}: {
  username: string;
  password: string;
}): Promise<Auth0Result> {
  return new Promise((resolve, reject) => {
    webAuth.client.login(
      { realm: 'Username-Password-Authentication', username, password },
      async function webAuthLoginCallback(error, result) {
        if (error) {
          Sentry.configureScope((scope) => scope.setLevel('error'));
          Sentry.setExtras({
            statusCode: error.statusCode,
            username,
            code: error.code,
          });
          Sentry.captureMessage('Auth0 login issue');
          return reject(error);
        }

        saveToken(result);
        channel?.postMessage({ type: 'login', payload: result });
        return resolve(result);
      }
    );
  });
}

/***
 * This is used by the `/signup` route
 */
export async function signup(payload: {
  email: string;
  password: string;
  userMetadata: {
    username: string;
    phone: string;
    hasToken: 'yes' | 'no';
  };
}): Promise<Auth0Result> {
  return new Promise((resolve, reject) => {
    webAuth.signupAndAuthorize(
      { connection: 'Username-Password-Authentication', ...payload },
      function webAuthLoginCallback(error, result) {
        if (error) {
          // we only report a non user_exists errors
          if (error.code !== 'user_exists') {
            Sentry.configureScope((scope) => scope.setLevel('error'));
            Sentry.setExtras({
              statusCode: error.statusCode,
              username: payload.email,
              code: error.code,
            });
            Sentry.captureMessage('Auth0 signup issue');
          }
          return reject(error);
        }

        saveToken(result);
        setSentryUser(payload.email);
        channel?.postMessage({ type: 'signup', payload: result });
        return resolve(result);
      }
    );
  });
}

/***
 * This is used by the `/reset-password` and `/user/settings` routes
 */
export async function changePassword(email: string): Promise<string> {
  return new Promise((resolve, reject) => {
    webAuth.changePassword(
      { connection: 'Username-Password-Authentication', email },
      function (error, result) {
        // @iamdustan was unable to trigger an error case through user behavior
        // during testing—I’m guessing that errors come through if auth0 is down
        // and we should be defensive on that
        // istanbul ignore next
        if (error) {
          reject(error);
        } else {
          resolve(result);
        }
      }
    );
  });
}

export function logout() {
  removeCookie('jwt');
  removeCookie('idToken');
  removeCookie('organization');

  channel?.postMessage({ type: 'logout' });
}

export const userCookieExist = (context?: GetServerSidePropsContext['req']) =>
  !!getCookie('jwt', context) && !!getCookie('idToken', context);

function saveToken(authResult: Auth0Result) {
  if (!isBrowser) {
    return;
  }
  setCookie('jwt', authResult.accessToken);
  setCookie('idToken', authResult.idToken);
}

export const getToken = (ctx?: NextPageContext) =>
  // @ts-expect-error need to unify NextPageContext, NextAppContext, and
  // GetServerSidePropsContext['req']
  getCookie('jwt', ctx?.req);
