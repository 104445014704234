const MEDIAN_KEY = '29d45627-8e72-471a-9c74-6118ef9c1b6c';

// Loads Median for cobrowsing
export function initializeMedian() {
  !(function () {
    var e = (window.Median = window.Median || []);
    if (!e._initialized)
      if (e._snippet_loaded) console.warn('Median Snippet loaded twice.');
      else {
        (e._snippet_loaded = !0),
          (e._snippet_version = 3),
          (e.methods = ['init', 'identify', 'endSession', 'on']),
          (e.factory = function (n) {
            return function () {
              var t = Array.prototype.slice.call(arguments);
              e.push([n, t]);
            };
          });
        for (var n = 0; n < e.methods.length; n++) {
          var t = e.methods[n];
          e[t] = e.factory(t);
        }
        var i = document.createElement('script');
        (i.type = 'text/javascript'),
          (i.async = !0),
          (i.src = 'https://js.hellomedian.com/v1/mdn-screenshare.js');
        var a = document.getElementsByTagName('script')[0];
        a.parentNode.insertBefore(i, a);
      }
  })();
  Median.init(MEDIAN_KEY);
}
