/**
 * This is the primary locale settings source
 * All locale settings for the application are initialized here
 * those include:
 *
 * In Profile Settings:
 * – Time Format
 * – Date Format
 * – Time Zone
 * In Organization Settings:
 * – Currency
 * – Country Code
 * – Address Style (Universal/US)
 * – Measurement Units (Metric/Imperial)
 *
 *
 * Tests of this module are primarily done in behavior tests through the
 * <SubscriptionGuard />
 */

import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import flagsmith from 'flagsmith';
import { toast } from 'react-toastify';

import { queryCurrentUser } from 'gql/queries';
import { DefaultVetClinic, Organization } from 'types/organization';
import { Person, PersonOrganizationPermission, User } from 'types/person';
import { Loader } from 'components/loader';
import { userCookieExist } from 'lib/auth';
import { getCookie, setCookie } from 'lib/session';
import { createStrictContext } from 'helpers/createStrictContext';
import { computersTimezone, LocaleSettings } from 'helpers/localization';

export type FeatureFlag =
  | 'petfinder'
  | 'adopt_a_pet'
  /**
   * We are releasing the initial Partners release separate from additional
   * planned features. For the sake of brevity, we’ve kept our `partners` flag
   * and added a `partners_v2` flag to hide Common Pets and Activity.
   * This shouldn’t assume we will follow the at development and this was
   * primarily for descoping the v1 release.
   */
  | 'partners_v2'
  | 'payments_route'
  | 'reporting_create'

  // While the backend work is being completed for these features, this
  // flag will allow us to hide the UI elements from view.
  | 'new_medical'
  | 'TBD';

export type UserOrganization = Organization & {
  role: PersonOrganizationPermission['role'];
};
export type UserContextType = {
  // omg userId !== user.id
  userId: string;
  user: Person | null;
  organizations: readonly UserOrganization[];
  activeOrganization: UserOrganization | null;
  localeSettings: LocaleSettings;
  econtractsEnabled: boolean;
  onChangeOrganization(organizationId: string): Promise<void>;
  featureFlags: Map<FeatureFlag, boolean>;
  defaultVetClinic: DefaultVetClinic;
};

/**
 * In UserContext, we take the server model of:
 *   user_permissions: { organization, role }
 * and map this to `{ ...organization, role }` so that we can conveniently
 * lookup the users role within the currentOrganization during reading
 */
function mapRoleOntoOrganization(
  userPermissions: ReadonlyArray<PersonOrganizationPermission>
): ReadonlyArray<UserOrganization> {
  return userPermissions.reduce((acc, n) => {
    if (n.role === 'SUPER_ADMIN') {
      return acc;
    }
    const userOrg = { ...n.organization, role: n.role } as UserOrganization;
    acc.push(userOrg);
    return acc;
  }, [] as Array<UserOrganization>);
}

/* istanbul ignore next */
const noopPromise = () => Promise.resolve(void 0);
export const [UserContext, useUserContext] =
  createStrictContext<UserContextType>({
    user: null,
    organizations: [],
    activeOrganization: null,
    // @ts-expect-error
    localeSettings: null,
    econtractsEnabled: false,
    onChangeOrganization: noopPromise,
    featureFlags: new Map(),
  });

type UserCurrentResponse = {
  user_current: User;
  user_permissions: PersonOrganizationPermission[];
};
function initFlagsmith({
  userId,
  organizations,
  onChange,
}: {
  userId: string;
  organizations: ReadonlyArray<UserOrganization>;
  onChange: Function;
}) {
  flagsmith.init({
    environmentID: process.env.BULLET_KEY as string,
    identity: userId,
    traits: { organizations: organizations.map(({ id }) => id).join(', ') },
    cacheFlags: true, // stores flags in localStorage cache
    onChange: () => {
      const flags = flagsmith.getAllFlags();

      // We are not caring about the flags id and value. Just if its enabled or not.
      const normalizedFlags = Object.entries(flags).reduce(
        (map, [key, flag]) => {
          map.set(key as FeatureFlag, flag.enabled);
          return map;
        },
        new Map<FeatureFlag, boolean>()
      );

      onChange(normalizedFlags);
    },
  });
}

export function UserProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const router = useRouter();
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<UserCurrentResponse>();
  const [featureFlags, setFeatureFlags] = useState(() => new Map());
  const [organizations, setOrganizations] = useState<
    readonly UserOrganization[]
  >([]);
  const [activeOrganization, setActiveOrganization] =
    useState<UserOrganization | null>(null);

  const initialize = useCallback(
    async function initialize() {
      // Check if the user is logged in, and if they aren't we bail out
      // early and redirect them to a login page
      if (!userCookieExist()) {
        router.push(`/login?forward_url=${window.location.pathname}`);
        return;
      }

      // Now that we know the user is logged in, we fetch user data
      client.watchQuery({ query: queryCurrentUser }).subscribe({
        next({ data }) {
          // From userData and with the organization cookie, we determine the active
          // organization for the user
          const organizations = mapRoleOntoOrganization(data.user_permissions);

          initFlagsmith({
            userId: data.user_current.id,
            organizations,
            onChange: setFeatureFlags,
          });

          const activeOrganizationId = getCookie('organization');
          const activeOrganization =
            organizations.find(({ id }) => id === activeOrganizationId) ||
            organizations[0];

          setCookie('organization', activeOrganization?.id);

          // Lastly, we can persist this all to state
          setUserData(data);
          setOrganizations(organizations);
          setActiveOrganization(activeOrganization);
          setLoading(false);
        },
        error(err) {
          // If there was an error with the network request, redirect to login
          toast('Please log in to continue, redirecting to login page', {
            type: 'error',
          });
          router.push('/login');
          throw err;
        },
      });
    },
    // we do not want to trigger a hook refresh on each router change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client]
  );

  const onChangeOrganization = useCallback(
    async function onChangeOrganization(organizationId: string) {
      let activeOrganization = organizations.find(
        ({ id }) => id === organizationId
      );

      if (activeOrganization) {
        setCookie('organization', activeOrganization.id);
        setActiveOrganization(activeOrganization);
        localStorage.removeItem('paw_defaultVetClinic'); // TODO: remove during backend integration
      } else {
        // Now that we know the user is logged in, we fetch user data
        await client.query<UserCurrentResponse>({
          query: queryCurrentUser,
          // note that network-only still writes to the cache
          fetchPolicy: 'network-only',
        });
      }

      router.push('/');
    },
    // we do not want to trigger a hook refresh on each router change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizations, client]
  );

  useEffect(() => {
    initialize();
    // this is an on-mount only effect
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // is we has suspense, we could just throw while loading, but alas
  if (loading) {
    return <Loader />;
  } else {
    return (
      <UserProviderImpl
        userData={userData!}
        organizations={organizations}
        activeOrganization={activeOrganization}
        onChangeOrganization={onChangeOrganization}
        featureFlags={featureFlags}
      >
        {children}
      </UserProviderImpl>
    );
  }
}
function UserProviderImpl({
  userData,
  organizations,
  activeOrganization,
  onChangeOrganization,
  featureFlags,
  children,
}: PropsWithChildren<{
  userData: UserCurrentResponse;
  organizations: ReadonlyArray<UserOrganization>;
  activeOrganization: UserOrganization | null;
  featureFlags: Map<FeatureFlag, boolean>;
  onChangeOrganization(organizationId: string): Promise<void>;
}>) {
  const {
    user_current: { profile, id: userId, settings: userLocaleSettings },
  } = userData;

  const orgSettings = activeOrganization?.settings;
  const econtractsEnabled = orgSettings?.econtracts_enabled || false;
  const defaultVetClinic = orgSettings?.default_vet_clinic || null;
  const localeSettings = useMemo(() => {
    return {
      dateFormat: userLocaleSettings?.date_format || 'MMDDYYYY',
      timeFormat: userLocaleSettings?.time_format || '12',
      timezone: userLocaleSettings?.timezone || computersTimezone,
      currency: orgSettings?.default_currency || 'USD',
      unitsOfMeasurement: orgSettings?.measurement_system || 'IMPERIAL',
      countryCodePrefix: orgSettings?.phone_country_code || '1',
      addressStyle: orgSettings?.address_style || 'US',
    } as LocaleSettings;
  }, [userLocaleSettings, orgSettings]);

  const value = useMemo(
    () => ({
      userId,
      user: profile,
      organizations,
      activeOrganization,
      localeSettings,
      econtractsEnabled,
      onChangeOrganization,
      featureFlags,
      defaultVetClinic,
    }),
    [
      userId,
      profile,
      organizations,
      activeOrganization,
      localeSettings,
      econtractsEnabled,
      onChangeOrganization,
      featureFlags,
      defaultVetClinic,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

/**
 * `useFeatureFlags` is a hook to enable clients to read available beta flags
 * based on the current User Context
 *
 * usage: `const featureEnabled = useFeatureFlag('textTemplates');`
 */
export function useFeatureFlag(flag: FeatureFlag): boolean {
  const { featureFlags } = useUserContext();
  return featureFlags.has(flag) && (featureFlags.get(flag) as boolean);
}
