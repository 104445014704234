import gql from 'graphql-tag';

export const AddressFragment = gql`
  fragment AddressFragment on Address {
    postal_code
    country_code
    subdivision_code
    locality
    line_1
    line_2
  }
`;

// if you change this shape, ensure you update the type def in types/note.ts
export const NoteFragment = gql`
  fragment NoteFragment on Note {
    author {
      profile {
        photo {
          url
        }
        first_name
        last_name
        email
      }
    }
    date
    id
    text
  }
`;

export const PersonFragment = gql`
  fragment PersonFragment on Person {
    id
    first_name
    last_name
    email
    phone
    activities
    birthdate
    volunteer_distance_meters
    adoption_application_id
    foster_application_id
    surrender_application_id
    volunteer_application_id
    tags
    notes {
      ...NoteFragment
    }
    photo {
      id
      url
    }
    address {
      ...AddressFragment
    }
    documents {
      description
      id
      tags
      date
      url
      ... on EcontractArtifact {
        econtract {
          id
          person {
            id
            first_name
            last_name
          }
          template {
            id
            title
          }
          signature {
            id
            date
          }
        }
      }
    }
    partner_persons {
      partner {
        id
        name
        primary_email
        primary_phone
        partner_types
        logo {
          id
          url
          description
        }
        partner_organization {
          id
          name
        }
      }
      roles
    }
  }
  ${AddressFragment}
  ${NoteFragment}
`;

// only used by queryCurrentUserHandler
export const OrgFragment = gql`
  fragment OrgFragment on Organization {
    __typename
    id
    name
    email
    nonprofit_501_c_3
    created_at
    phone
    website
    type
    about
    mission_statement
    logo {
      __typename
      id
      url
    }
    settings {
      __typename
      stripe_customer_id
      stripe_subscription_id
      address_style
      phone_country_code
      default_currency
      measurement_system
      econtracts_enabled
      default_vet_clinic {
        vet {
          person {
            id
            first_name
            last_name
          }
        }
        clinic {
          id
          name
        }
      }
    }
    primary_address {
      __typename
      ...AddressFragment
    }
  }
  ${AddressFragment}
`;
