/**
 * This file is solely responsible for configuring our Apollo Client across SSR,
 * SSG, and client-side rendering contexts. Changes to this file should be made
 * with great care.
 **/
import { NextPageContext } from 'next';
import Router from 'next/router';
import { useMemo } from 'react';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import {
  ApolloCache,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { print, GraphQLError } from 'graphql';
import * as Sentry from '@sentry/browser';

import { getToken } from './auth';

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
type TClient = ReturnType<typeof createApolloClient>;
let globalApolloClient: null | TClient = null;
export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 * @param  {NextPageContext} ctx
 */
export function initApolloClient(initialState: any, ctx?: NextPageContext) {
  const client = globalApolloClient ?? createApolloClient(ctx);

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = client.extract();

    // TODO: this should probably use the other merge object we have
    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    client.cache.restore(data);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return client;
  // Create the Apollo Client once in the client
  if (!globalApolloClient) globalApolloClient = client;
  return globalApolloClient;
}

function createOriginWideCache<TCacheShape>(
  baseCache: ApolloCache<TCacheShape>,
  cacheId: string
): ApolloCache<TCacheShape> {
  if (typeof BroadcastChannel === 'undefined') {
    return baseCache;
  }

  const writeChannel = new BroadcastChannel(`${cacheId}::write`);
  writeChannel.onmessage = (messageEvent) => {
    baseCache.write(messageEvent.data);
  };

  type WriteFunction = ApolloCache<TCacheShape>['write'];
  const write: WriteFunction = function write(options) {
    writeChannel.postMessage(options);
    return baseCache.write(options);
  };

  const originCache = Object.assign({}, baseCache, { write });

  Object.setPrototypeOf(originCache, Object.getPrototypeOf(baseCache));

  return originCache;
}

type PageProps = any;
/**
 * `addApolloState` is used for querying apollo data on the server and ensuring
 * it is available to the pages
 */
export function addApolloState(client: TClient, pageProps: PageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

/**
 * `useApollo` is how we get the client into the pages/_app.tsx.
 */
export function useApollo(pageProps: PageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initApolloClient(state), [state]);
  return store;
}

/**
 *
 * Above this line is the public interface from our apollo configuration code.
 * Below this is where we teach Apollo how to merge our data types together,
 * configure authentication, and error handling.
 *
 */

function apiGraphqlErrorTrap(error: GraphQLError) {
  Sentry.configureScope((scope) => scope.setLevel('error'));
  Sentry.captureMessage(error.message, {
    tags: {
      locations: error.locations?.join(', '),
      path: error.path?.join(', '),
    },
  });
}

function apiNetworkErrorTrap(error: Error) {
  Sentry.configureScope((scope) => scope.setLevel('error'));
  Sentry.captureException(error);
}

function forcefullyLogout() {
  const isBrowser = typeof global.window !== 'undefined';
  if (isBrowser) {
    if (!(window.location.pathname === '/signup')) {
      Router.push('/logout');
    }
  }
}

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    // TODO: delete me when we add the proper textTemplates support
    // istanbul ignore next
    if (operation.operationName === 'Query__devTextTemplates') {
      console.warn(
        'Reset text templates state due to a corruption between stored and required options.'
      );
      localStorage.removeItem('paw_textTemplates');
    }
    console.groupCollapsed(
      '%cPawlytics GraphQL errors captured:\n  ' +
        graphQLErrors.map((n) => n.message).join('  \n'),
      'color: red; font-weight: bold; padding: 0 4px;'
    );
    console.log(
      'Query:\n%s\nVariables: %s\n\nErrors: %s',
      print(operation.query),
      JSON.stringify(operation.variables || {}, null, 4),
      JSON.stringify(graphQLErrors || {}, null, 4)
    );
    console.groupEnd();

    graphQLErrors.forEach((err) => {
      apiGraphqlErrorTrap(err);
      // Forbidden requests means that a user attempted an action they are not
      // allowed to do. This does not mean they should be forcefully logged
      // out, but we should fail silently in the UI so they do not see that it
      // was forbidden. For example, a customer opening a stale token invite
      // will be forcefully logged out. We should handle this at the page
      // layer.
      // if (err.extensions?.category === 'forbidden') {
      //    forcefullyLogout();
      // }
    });
  }
  if (networkError && 'response' in networkError) {
    switch (networkError.response?.status) {
      case 504:
      case 503:
      case 500:
        // Check if error response is JSON
        // If not replace parsing error message with real one
        if ('bodyText' in networkError) {
          try {
            JSON.parse(networkError.bodyText);
          } catch (e) {
            networkError.message = networkError.bodyText;
          }
        }
        apiNetworkErrorTrap(networkError);
        break;

      case 403:
        forcefullyLogout();
        break;
      default:
        apiNetworkErrorTrap(networkError);
        break;
    }
  } else if (networkError) {
    apiNetworkErrorTrap(networkError);
  }
});
const httpLink = createHttpLink({ uri: process.env.PAWLYTICS_API });
const retryLink = new RetryLink({
  attempts: (count, _operation, error) => {
    // we attempt one retry for >= 500 Errors like Gateway Timeout and Service Unavailable
    if (error.response?.status >= 500) {
      return count < 2;
    }
    return false;
  },
});

function createApolloClient(ctx?: NextPageContext) {
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = getToken(ctx);
    return token
      ? { headers: { ...headers, Authorization: `Bearer ${token}` } }
      : { headers };
  });

  function defaultMergeObjects<T extends object>(existing: T, incoming: T): T {
    return { ...existing, ...incoming };
  }
  const cache = new InMemoryCache({
    typePolicies: {
      PartnerOrganization: {
        fields: {
          primary_address: { merge: defaultMergeObjects },
        },
      },
      Partner: {
        fields: {
          primary_address: { merge: defaultMergeObjects },
          persons: { merge: defaultMergeObjects },
        },
      },
      Organization: {
        fields: {
          primary_address: { merge: defaultMergeObjects },
          settings: { merge: defaultMergeObjects },
        },
      },
      // WARNING: this is only for development ahead of the backend implementation
      // reference: to use this, follow docs in <PROJECT_ROOT>/docs/graphql.md
      /*
      Query: {
        fields: {
          textTemplates: {
            read() {
              return textTemplatesVar();
            },
          },
        },
      },
      */
    },
  });

  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([authLink, errorLink, retryLink, httpLink]),
    cache: createOriginWideCache(cache, 'pawlytics-app'),
  });
}
