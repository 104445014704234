import 'page-components/common/init.css';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { ToastContainer, Flip } from 'react-toastify';
import { PublicPageLayout } from 'components/layouts/public-page';
import { UserProvider } from 'state/UserContext';

import { initializeMedian } from 'helpers/median';
import { useApollo } from 'lib/apollo';

type Props = AppProps & {
  idToken: Object | undefined;
  hash: undefined;
  apolloClient: ApolloClient<any>;
};

export default function PawlyticsApp(props: Props) {
  const { Component, pageProps, hash } = props;
  const apolloClient = useApollo(pageProps);

  useEffect(() => {
    // we do run this on the server, but `useEffect` is *not* run on the server
    // eslint-disable-next-line no-restricted-globals
    if (location.hostname.match('pawlytics.com')) {
      initializeMedian();
    }
  }, []);

  const publicRoutes = [
    '/login',
    '/logout',
    '/signup',
    '/accept-invite',
    '/reset-password',
    '/public',
  ];

  if (props.router.route.startsWith('/public/pet-iframe')) {
    return (
      <ApolloProvider client={apolloClient}>
        <Head>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width"
          />
          <link rel="icon" type="image/x-icon" href="/favicon.png" />
        </Head>
        <Component {...pageProps} />
      </ApolloProvider>
    );
  }

  const children = publicRoutes.some((route) =>
    props.router.route.startsWith(route)
  ) ? (
    <PublicPageLayout>
      <Component {...pageProps} />
    </PublicPageLayout>
  ) : (
    // TODO: pass the apollo client from here to <UserProvider />
    <UserProvider>
      <Component {...pageProps} hash={hash} />
    </UserProvider>
  );

  return (
    <ApolloProvider client={apolloClient}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <link rel="icon" type="image/x-icon" href="/favicon.png" />
        {hubspot}
      </Head>
      {children}
      <ToastContainer
        pauseOnFocusLoss={false}
        position="bottom-left"
        theme="colored"
        closeOnClick
        draggable
        hideProgressBar
        autoClose={2500}
        transition={Flip}
      />
    </ApolloProvider>
  );
}

const hubspot = (
  <script
    type="text/javascript"
    id="hs-script-loader"
    async
    defer
    src="//js.hs-scripts.com/8669442.js"
  />
);
